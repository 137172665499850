import * as React from 'react';

import { generateInputStyles } from './Forms/inputStylesHelper';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, error, readOnly, ...props }, ref) => {
  const inputStyle = generateInputStyles({
    className,
    isError: !!error,
    readOnly,
  });
  return <input type={type} className={inputStyle} ref={ref} {...props} />;
});
Input.displayName = 'Input';

export interface InputWithOnChangeProps extends Omit<InputProps, 'onChange'> {
  onChange?: (value: string) => void;
}

const InputWithOnChange = React.forwardRef<HTMLInputElement, InputWithOnChangeProps>(({ onChange, ...props }, ref) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  return <Input {...props} ref={ref} onChange={handleChange} />;
});
InputWithOnChange.displayName = 'InputWithOnChange';

export { Input, InputWithOnChange };
