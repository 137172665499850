import auth0 from 'auth0-js';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import type { AuthConfig, AuthContextData, AuthData } from './AuthProvider.types';

export const ACCESS_TOKEN_LS_KEY = 'token';
export const EXPIRES_AT_LS_KEY = 'expires_at';

const AuthContext = createContext<AuthContextData | undefined>(undefined);

export function AuthProvider({ children, config }: { children: ReactNode; config: AuthConfig }) {
  const [error, setError] = useState<any>(null);
  const [codeLoading, setCodeLoading] = useState(false);

  const auth0Client = useMemo(() => {
    return new auth0.WebAuth({
      domain: config.domain,
      clientID: config.clientID,
      audience: config.audience,
      responseType: 'token id_token',
      redirectUri: config.redirectUri,
    });
  }, [config]);

  const setAuthData = (authResult: AuthData) => {
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    localStorage.setItem(ACCESS_TOKEN_LS_KEY, authResult.accessToken);
    localStorage.setItem(EXPIRES_AT_LS_KEY, expiresAt);
  };

  const clearAuthData = () => {
    localStorage.removeItem(ACCESS_TOKEN_LS_KEY);
    localStorage.removeItem(EXPIRES_AT_LS_KEY);
  };

  const isAuthenticated = useCallback(() => {
    try {
      const expiresAt = JSON.parse(localStorage.getItem(EXPIRES_AT_LS_KEY) ?? '0');
      return new Date().getTime() < expiresAt;
    } catch (e) {
      return false;
    }
  }, []);

  const signOut = useCallback(
    async (reload = false) => {
      clearAuthData();
      window.location.hash = '';
      auth0Client.logout({
        returnTo: `${window.location.origin}`,
        clientID: config.clientID,
      });

      reload && window.location.reload();
    },
    [auth0Client, config.clientID],
  );

  const sendSmsCode = useCallback(
    async (email: string) => {
      setCodeLoading(true);
      return new Promise((resolve, reject) => {
        auth0Client.passwordlessStart(
          {
            connection: 'sms',
            send: 'code',
            phoneNumber: email,
          },
          function (err, res) {
            setCodeLoading(false);
            if (err) {
              setError(err);
              reject(err);
              return;
            }
            if (res) {
              resolve(res);
            }
          },
        );
      });
    },
    [auth0Client],
  );

  const parseAuthToken = useCallback(async () => {
    return new Promise(resolve => {
      if (window.location.hash) {
        auth0Client.parseHash((err, authResult) => {
          if (authResult?.accessToken) {
            setAuthData(authResult as AuthData);
            auth0Client.client.userInfo(authResult.accessToken, err => {
              if (err) {
                console.error('Error getting user info:', err);
              }
            });
            resolve(authResult.state);
          }
          if (err) {
            console.error('Error parsing auth hash:', err);
            return null;
          }
        });
      }
    });
  }, [auth0Client]);

  const verifySmsCode = useCallback(
    async (email: string, verificationCode: string, state?: string, nonce?: string) => {
      return new Promise((resolve, reject) => {
        auth0Client.passwordlessLogin(
          {
            connection: 'sms',
            phoneNumber: email,
            verificationCode: verificationCode,
            state: state || '',
            nonce: nonce || '',
          },
          (err, res) => {
            if (err) {
              setError(err);
              reject(err);
              return;
            }
            if (res) {
              resolve(res);
            }
          },
        );
      });
    },
    [auth0Client],
  );

  const authState = useMemo(
    () => ({
      sendSmsCode,
      signOut,
      error,
      verifySmsCode,
      parseAuthToken,
      isAuthenticated,
      codeLoading,
    }),
    [sendSmsCode, signOut, error, verifySmsCode, parseAuthToken, isAuthenticated, codeLoading],
  );

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
